import React from "react"
import { graphql } from "gatsby"
import { Grid, Flex, Title } from "../../components/system"
import Seo from "../../components/seo"

import Layout from "../../components/layout"
import Product from "../../components/Product"

const Brand = ({ data }) => {
  const products = data.allSanityProduct.nodes
  const brand = data.sanityBrand

  return (
    <Layout dark>
      <Seo
        title={brand.name}
        description={brand.bio}
        image={brand.seo?.asset.src}
      />
      <Title pt={150} pb={5}>
        {brand.name}
      </Title>
      <Flex>
        <Grid
          gridGap={50}
          gridTemplateColumns={["1fr", "1fr 1fr", "1fr 1fr 1fr"]}
          mx={[2, 4, 4]}
          mb={5}
        >
          {products.map((product, key) => (
            <Product data={product} key={key} />
          ))}
        </Grid>
      </Flex>
    </Layout>
  )
}

export default Brand

export const pageQuery = graphql`
  query brand($brand: String) {
    sanityBrand(name: { eq: $brand }) {
      name
      bio
      seo {
        asset {
          url
        }
      }
    }
    allSanityProduct(filter: { brand: { name: { eq: $brand } } }) {
      nodes {
        id
        name
        price
        empty
        slug {
          current
        }
        brand {
          name
        }
        images {
          asset {
            gatsbyImageData(aspectRatio: 0.8)
          }
        }
      }
    }
  }
`

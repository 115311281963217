import React, { useState } from "react"
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { Flex, Box, Text, Label } from "../system"

const Product = ({ data }) => {
  const [hover, setHover] = useState(false)
  return (
    <Link
      to={`/shop/product/${data.slug.current}`}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <Flex flexDirection="column" width={["90vw", "40vw", "27vw"]}>
        <Box width="100%">
          <GatsbyImage
            alt={data.name}
            image={data.images[0].asset.gatsbyImageData}
          />
        </Box>
        <Flex justifyContent="space-between" alignItems="flex-start" mt={2}>
          <Box>
            <Label down textAlign="left" fontSize={2}>
              {data.name}
            </Label>
            <Text mb={0} textAlign="left" color="faded" fontSize={1}>
              {data.brand.name}
            </Text>
          </Box>
          <Box ml={3}>
            <Text mb={0} textAlign="right">
              £{data.price}
            </Text>
          </Box>
        </Flex>
      </Flex>
    </Link>
  )
}
export default Product
